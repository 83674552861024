<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-form ref="mainForm">
          <v-row class="mt-3">
             <v-col cols="12" md="12">
                <v-toolbar dark color="panel1">
                  <v-toolbar-title>Info</v-toolbar-title>
              </v-toolbar>             
              <v-card class="mt-5">                
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field v-model="form.email_setting_id" disabled label="Rec Id" outlined></v-text-field>                                                                
                        <v-text-field v-model="form.category" readonly label="Category" :rules="inputRules" outlined></v-text-field>
                        <v-text-field v-model="form.name" readonly label="Name" :rules="inputRules" outlined></v-text-field>
                        <v-text-field v-model="form.code" readonly label="Code" :rules="inputRules" outlined></v-text-field>
                        <v-text-field v-model="form.method" readonly label="Method" :rules="inputRules" outlined></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
             </v-col>
          </v-row>
          <v-row class="mt-3">
             <v-col cols="12" md="12">
                <v-toolbar dark color="panel2">
                  <v-toolbar-title>Body</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5 pa-5">
                    <v-textarea 
                      label="Body"
                      v-model="form.body"
                      rows="15"
                      outlined>
                    </v-textarea>                              
                </v-card>
             </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" md="12">          
              <v-toolbar dark color="panel3">
                  <v-toolbar-title>Header Setting</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
              </v-toolbar>             
              <v-card class="mt-3">
                    <v-list>
                        <v-list-item  v-for="(valueRec,index) in headerList" :key="index">
                            <InputField :inputName="index" :inputProp="inputProp['header']" 
                            :content.sync="headerList[index]"></InputField>
                        </v-list-item>
                    </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" md="12">          
              <v-toolbar dark  color="blue darken-2">
                  <v-toolbar-title>SMTP Setting</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
              </v-toolbar>             
              <v-card class="mt-3">
                    <v-list>
                        <v-list-item  v-for="(valueRec,index) in smtpSettingList" :key="index">
                            <InputField :inputName="index" :inputProp="inputProp['smtp_setting']" 
                            :content.sync="smtpSettingList[index]"></InputField>
                        </v-list-item>
                    </v-list>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col cols="12" md="12">          
                <v-toolbar dark color="panelSysInfo">
                  <v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-3 pa-5">
                      <v-row>
                          <v-col cols="12" md="12">
                              <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                          </v-col>
                      </v-row>
                </v-card>
            </v-col>
          </v-row>
        </v-form>
    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import InputField from '@/components/InputField'
import ApSettingService from "@/services/ApSettingService"
//import Editor from "@/components/Editor.vue";


export default {
  components:{DlgMessage,InputField},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Settings',disabled: false,href:'/',},
              {text:'TX Email',disabled: false,href:'/setttings',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            valueRec:[],
            headerList:[],
            smtpSettingList:[],
            externalSettingList:[],
            routeParam:[],
            form:{login_id:null,email_setting_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',
            inputProp:[],
            inputValue:[],

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['email_setting_id'] = this.$route.params.email_setting_id;
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        let title = "View TX Setting"
        this.form.email_setting_id = this.routeParam.email_setting_id
        try{
            ApSettingService.view_tx_email(this.form).then((res) => {
                this.form = res.data.data;
                //console.log(this.form);
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                console.log(res.data);
                this.headerList = this.form.header;
                this.smtpSettingList = this.form.smtp_setting;
                this.externalSettingList = this.form.external_setting;
                this.inputProp = this.form.field_properties;
               
            }).catch((e)=>{
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            if(e)
                alert(e)
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        let title = "Update Setting"
        this.form.header = this.headerList;
        this.form.smtp_setting = this.smtpSettingList;
        this.form.external_setting = this.externalSettingList;
        if(this.$refs.mainForm.validate()) {
            try{
                ApSettingService.update_tx_email(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success.');
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                    alert(e)
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },

    moveItem(direction,index){
        this.saveIndicator = "(*)"
        let tmpArray1 = [];
        let tmpArray2 = [];
        switch (direction) {
            case 'up':
                if(index==0)
                    return;
                tmpArray1 = this.valueList[index-1];
                tmpArray2 = this.valueList[index];
                this.$set(this.valueList,index-1,tmpArray2);
                this.$set(this.valueList,index,tmpArray1);
                break;
            case 'down':
                if(index==this.valueList.length-1)
                    return;
                tmpArray1 = this.valueList[index+1];
                tmpArray2 = this.valueList[index];
                this.$set(this.valueList,index+1,tmpArray2);
                this.$set(this.valueList,index,tmpArray1);
                break;       
            default:
                break;
        }
    }

  }
}
</script>