import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApSettingService {


    list_sys_setting() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_sys_setting/list_sys_setting", form);

    }
    
    view_sys_setting(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_sys_setting/view_sys_setting", form);

    }

    update_sys_setting(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_sys_setting/update_sys_setting", form);

    }    
    

    list_tx_email() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_sys_setting/list_tx_email", form);

    }
    
    view_tx_email(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_sys_setting/view_tx_email", form);

    }

    update_tx_email(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_sys_setting/update_tx_email", form);

    }    



}

    
 

export default new ApSettingService();